import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import { environment } from '../../../environments/environment';
import { AuthorizationService } from '../../security/services/authorization.service';
import { SentryErrorHandlerService } from './sentry-error-handler.service';
import { LocalStorageService } from './local-storage.service';
import { WarehouseService } from './warehouse.service';
import { v4 as uuidv4 } from 'uuid';
import { CheckLogTimeService } from './check-log-time.service';
import { CheckLogTimeType } from '../enums/check-log-time-type.enum';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: Pusher;

  constructor(
    private authorizationService: AuthorizationService,
    private sentryService: SentryErrorHandlerService,
    private localStorageService: LocalStorageService,
    private warehouseService: WarehouseService,
    private checkLogTimeService: CheckLogTimeService,
  ) {}

  pusherInit() {
    this.pusher = new Pusher(environment.pusher.app_key, {
      wsHost: environment.pusher.host || null,
      cluster: environment.pusher.cluster || null,
      wsPort: environment.pusher.port || null,
      forceTLS: environment.pusher.forceTLS,
      encrypted: environment.pusher.encrypted,
      disableStats: environment.pusher.disableStats,
      enabledTransports: environment.pusher.enabledTransports || null,
      // @ts-ignore
      userAuthentication: {
        endpoint: `${environment.wms_api_url}${environment.pusher.user_auth_endpoint}`,
        headers: {
          'x-auth-token': this.authorizationService.getToken(),
        },
      },
      // @ts-ignore
      channelAuthorization: {
        endpoint: `${environment.wms_api_url}${environment.pusher.channel_auth_endpoint}`,
        headers: {
          'x-auth-token': this.authorizationService.getToken(),
        },
      },
    });
  }

  connect() {
    if (this.pusher?.connection?.state === 'connecting' || this.pusher?.connection?.state === 'connected') return;

    this.pusherInit();
    this.pusher.connect();

    this.pusher.connection.bind('error', error => {
      this.checkInvalidSignature(error);
      this.sentryService.sendError(error);
    });

    this.pusher.signin();
    this.subscribe(null, 'private-session.');
    this.checkLogTimeService.log('start', 'session', CheckLogTimeType.SESSION);
  }

  buildChannelName(manualChannel: string = null, prefix: string = null, postfix: string = null) {
    let channel = null;
    if (!manualChannel) {
      const userId = this.authorizationService.getLoggedUserId();
      let deviceId = this.localStorageService.getItem(LocalStorageKeysEnum.DEVICE_ID);
      let displayMode = 'pwa';

      if (!deviceId) {
        deviceId = uuidv4();
        this.localStorageService.setItem('deviceId', deviceId);
      }

      if (window.matchMedia('(display-mode: browser)').matches) {
        displayMode = 'browser';
      }

      channel = `${prefix ?? ''}warehouse-${
        this.warehouseService.warehouseId
      }.employee-${userId}.${displayMode}-${deviceId}${postfix ?? ''}`;
    } else {
      channel = manualChannel;
    }

    return channel;
  }

  subscribe(manualChannel: string = null, prefix: string = null, postfix: string = null, saveChannel = false): Channel {
    if (document.visibilityState === 'visible') {
      const channel = this.buildChannelName(manualChannel, prefix, postfix);
      if (saveChannel) {
        this.localStorageService.setItem('connectedChannel', channel);
      }
      if (
        this.pusher?.connection?.state !== 'connected' &&
        this.pusher?.connection?.state !== 'connecting' &&
        this.pusher?.connection?.state !== 'reconnecting'
      )
        this.connect();

      return this.pusher
        .subscribe(channel)
        .bind(channel, () => {})
        .bind('pusher:subscription_error', error => {
          this.checkInvalidSignature(error);
          this.sentryService.sendError(error);
        });
    }
  }

  unsubscribe(manualChannel: string = null, prefix: string = null, postfix: string = null) {
    const channelName = this.buildChannelName(manualChannel, prefix, postfix);
    const channel = this.pusher.channels.channels[channelName];
    if (channel) this.pusher?.unsubscribe(channelName);
  }

  unsubscribeAll() {
    this.checkLogTimeService.log('stop', 'session', CheckLogTimeType.SESSION);
    this.pusher.allChannels().forEach(channel => {
      channel.unsubscribe();

      const channelNameFragments = channel.name.split('.');
      const moduleName = channelNameFragments.at(-1);
      const moduleType = channelNameFragments[0];
      if (!moduleType.includes('session') && !moduleType.includes('broadcast'))
        this.checkLogTimeService.log('stop', moduleName, CheckLogTimeType.MAIN);
    });
  }

  checkInvalidSignature(error) {
    if (error?.data?.message?.includes('Invalid signature: Expected HMAC SHA256 hex digest')) {
      this.pusher.disconnect();
      this.pusher.connect();
      this.subscribe(this.localStorageService.getItem(LocalStorageKeysEnum.CONNECTED_CHANNEL));
    }
  }
}
