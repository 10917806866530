import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/core/api.service';
import { apiRoutes } from 'src/environments/api-routes';
import { PackingItem } from '../model/packItem.model';
import { ActivePackingItems } from '../model/active-packing-items';
import { PackPayload } from '../model/pack-payload';
import { Order } from '../model/order.model';
import { GenericObject } from '../../utils/models/generic-object';
import { SpecialPackingOrdersInterface } from '../model/special-packing-orders.interface';
import { AddProductToBoxModel } from '../model/add-product-to-box.model';
import { List } from '../../core/models/list';
import { OngoingOrderInterface } from '../model/ongoing-order.interface';

@Injectable({
  providedIn: 'root',
})
export class PackApiService {
  private apiRoutes = apiRoutes;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getBeingPackedOrders(): Observable<GenericObject> {
    return this.http.get<GenericObject>(this.apiService.getMikuUrl(this.apiRoutes.packing.root));
  }

  getOngoingSessions(): Observable<List<OngoingOrderInterface>> {
    return this.http.get<List<OngoingOrderInterface>>(this.apiService.getMikuUrl(this.apiRoutes.packing.ongoing, null));
  }

  getScannedOrder(barcode: string): Observable<Order> {
    return this.http.get<Order>(this.apiService.getMikuUrl(this.apiRoutes.scanner.order.replace('{barcode}', barcode)));
  }

  startPacking(orderId: string): Observable<void> {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.packing.root), { orderId });
  }

  deleteOrder(orderId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.deleteOrder.replace('{orderId}', orderId.toString())),
    );
  }

  getPackingOrder(orderId: number, preloader = true): Observable<ActivePackingItems> {
    return this.http.get<ActivePackingItems>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.getPackedItems.replace('{orderId}', orderId.toString()), null, {
        sortBy: 'pickedAt',
      }),
      {
        params: { preloader },
      },
    );
  }

  resetPacking(orderId: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.reset).replace('{orderId}', orderId.toString()),
      {},
    );
  }

  packOrder(payload: PackPayload): Observable<void> {
    return this.http.post<void>(this.apiService.getUrl(this.apiRoutes.packing.pack), payload);
  }

  wrongExpiryDateNotification(orderId: number, product: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getUrl(this.apiRoutes.packing.postWrongExpiryDate.replace('{orderId}', orderId.toString())),
      { product },
    );
  }

  endPacking(orderId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.finish).replace('{orderId}', orderId.toString()),
      {},
    );
  }

  specialPackingItemList(orderId: number): Observable<List<SpecialPackingOrdersInterface>> {
    return this.http.get<List<SpecialPackingOrdersInterface>>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.specialPacking.itemsList.replace('{id}', orderId.toString())),
    );
  }

  addBoxToOrder(order: number, package_size: number) {
    return this.http.post<void>(this.apiService.getMikuUrl(this.apiRoutes.package.addBoxToOrder), {
      order,
      package_size,
    });
  }

  addProductToBox(payload: AddProductToBoxModel): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.specialPacking.addProductToBox),
      payload,
    );
  }

  removeProductFromBox(payload: AddProductToBoxModel): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(this.apiRoutes.packing.specialPacking.removeProductFromBox),
      { body: payload },
    );
  }

  removeBox(packageOrder: number): Observable<void> {
    return this.http.delete<void>(
      this.apiService.getMikuUrl(
        this.apiRoutes.packing.specialPacking.removeBox.replace('{id}', packageOrder.toString()),
      ),
    );
  }

  checkWaybill(orderId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.package.fetchOrderWaybill.replace('{orderId}', orderId.toString())),
      {},
    );
  }

  sendShip(orderId: number): Observable<void> {
    return this.http.post<void>(
      this.apiService.getMikuUrl(this.apiRoutes.package.sendShip.replace('{orderId}', orderId.toString())),
      {},
    );
  }

  changeOrderStatus(orderId: number, status: string): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.getOrder.replace('{id}', orderId.toString())),
      {
        status,
      },
    );
  }

  pausePacking(orderId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.packingPause.replace('{orderId}', orderId.toString())),
      {},
    );
  }

  cancelPacking(orderId: number): Observable<void> {
    return this.http.put<void>(
      this.apiService.getMikuUrl(this.apiRoutes.sales.order.packingCancel.replace('{orderId}', orderId.toString())),
      {},
    );
  }
}
