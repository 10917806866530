export enum LocalStorageKeysEnum {
  JWT = 'UserToken',
  SESSION_JWT = 'SessionToken',
  PRIVILEGES = 'UserPrivileges',
  NAVBAR_OPEN = 'navBarOpen',
  ERROR_USER_FEEDBACK = 'errorUserFeedback',
  PUSHER_TRANSPORT_TLS = 'pusherTransportTLS',
  EXPIRED_TIME = 'expiredTime',
  LAST_LOGIN_DATE = 'lastLoginDate',
  AUTO_LOGOUT_TIME = 'autologoutTime',
  PAGE_REFRESH_COUNT = 'pageRefreshCount',
  SETTINGS = 'settings',
  LOGGED_USER = 'loggedUser',
  DEVICE_ID = 'deviceId',
  CONNECTED_CHANNEL = 'connectedChannel',
  COLLECTING_ACTIVE_SESSIONS = 'collectingActiveSessions',
  SENTRY_ERRORS = 'sentryErrors',
  WAREHOUSE = 'warehouse',
}
