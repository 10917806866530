export enum ReportsComponents {
  DELIVERY = 'delivery',
  PICKRETAIL = 'collectingRetail',
  PICKWHOLESALE = 'collectingWholesale',
  REPLENISHMENT = 'replenishment',
  PACKRETAIL = 'packingRetail',
  PACKWHOLESALE = 'packingWholesale',
  LABELING = 'sticker',
  GOODSMOVEMENT = 'goods-movement',
  TRANSFERREQUEST = 'transfer-request',
  RETURNS = 'returns',
  HANDLINGPALLETS = 'handling-pallets',
  ADDITIONAL = 'additional',
  OTHERS = 'others',
  PIM = 'pim',
  ORDERS = 'orders',
  LOCATIONS = 'locations',
  MASTERPALLETS = 'masterpallets',
  INVENTORYCHECK = 'inventoryCheck',
}
