import { Component } from '@angular/core';
import { PusherService } from '../../../core/services/pusher.service';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { SessionAnalyzerService } from '../../../core/services/session-analyzer.service';
import { BroadcastService } from '../../../core/services/broadcast.service';
import { LocalStorageKeysEnum } from '../../../core/enums/local-storage-keys.enum';

@Component({
  selector: 'app-stay-on-page-modal',
  templateUrl: './stay-on-page-modal.component.html',
})
export class StayOnPageModalComponent {
  constructor(
    private pusherService: PusherService,
    public dialogRef: MatDialogRef<StayOnPageModalComponent>,
    private localStorageService: LocalStorageService,
    private sessionAnalyzerService: SessionAnalyzerService,
    private broadcastService: BroadcastService,
  ) {
    dialogRef.disableClose = true;
  }

  confirm(): void {
    this.pusherService.connect();
    this.broadcastService.connect();
    this.pusherService.subscribe(this.localStorageService.getItem(LocalStorageKeysEnum.CONNECTED_CHANNEL));
    this.sessionAnalyzerService.init();
    this.dialogRef.close();
  }
}
