import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class PageRefreshDetectorService {
  count = 0;

  constructor(private localStorageService: LocalStorageService) {}

  observe() {
    const previousUrl = history?.state?.prevPage ?? null;
    const storageCount = parseInt(this.localStorageService.getItem(LocalStorageKeysEnum.PAGE_REFRESH_COUNT), 10);

    if (storageCount) this.count = storageCount;
    if (!previousUrl) {
      this.count++;
      this.localStorageService.setItem(LocalStorageKeysEnum.PAGE_REFRESH_COUNT, this.count);
    }
  }
}
