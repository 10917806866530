import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  constructor(private localStorageService: LocalStorageService) {}

  handleError(error: any) {
    if (environment.production) {
      const errorMsg = error.toString();
      const storageMap = this.localStorageService.getItem<string>(LocalStorageKeysEnum.SENTRY_ERRORS);
      let currentErrors;

      storageMap !== null
        ? (currentErrors = new Map(typeof storageMap === 'string' ? JSON.parse(storageMap) : storageMap))
        : (currentErrors = new Map());

      if (currentErrors.has(errorMsg)) {
        const errorDate = currentErrors.get(errorMsg);
        const diffTime = parseInt(moment.utc(moment(new Date()).diff(moment(new Date(errorDate)))).format('HH'), 10);

        if (diffTime >= 24) {
          currentErrors.delete(errorMsg);
          this.logError(error, currentErrors, errorMsg);
        } else {
          console.error(error);
        }
      } else {
        this.logError(error, currentErrors, errorMsg);
      }
    } else {
      console.error(error);
    }
  }

  logError(error: any, currentErrors, errorMsg) {
    currentErrors.set(errorMsg, new Date());
    this.localStorageService.setItem('sentryErrors', Array.from(currentErrors.entries()));
    this.sendError(error);
  }

  sendError(error: any) {
    Sentry.captureException(error.error || error.message || error.originalError || error);
  }

  sendCustomError(errorMsg: string, object: any) {
    Sentry.captureException(new Error(errorMsg), object);
  }
}
