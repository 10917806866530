import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PackageApiService } from '../../services/package/package-api.service';
import { tap } from 'rxjs/operators';
import { PackageModel } from '../../models/package.model';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-packing-select-package-modal',
  templateUrl: './packing-select-package-modal.component.html',
})
export class PackingSelectPackageModalComponent implements OnInit {
  @Output() onApprove = new EventEmitter();

  @Output() onReject = new EventEmitter();

  packageList: PackageModel[] = [];

  form = this.formBuilder.group({
    package: [null, [Validators.required]],
  });

  constructor(
    public dialogRef: MatDialogRef<PackingSelectPackageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private packageApiService: PackageApiService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.packageApiService
      .getPackageSizesList()
      .pipe(
        tap(({ items }) => {
          this.packageList = items.filter(({ shippingMethod }) => shippingMethod === this.data);
          if (this.packageList.length === 0) {
            this.toastrService.error('No package types for this shipping method. Please contact your administrator.');
          }

          if (this.packageList.length === 1) {
            // @ts-ignore
            this.form.patchValue({ package: this.packageList[0] });
            this.approve();
          }
        }),
      )
      .subscribe();
  }

  approve() {
    if (this.form.invalid) {
      this.toastrService.error('Please select package type');
      return;
    }

    this.onApprove.emit(this.form.value);
    this.dialogRef.close();
  }

  reject() {
    this.onReject.emit();
    this.dialogRef.close();
  }
}
