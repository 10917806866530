import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MikuProductModel } from '../../../PIM/models/miku-product.model';
import { AddProductModalTypeEnum } from '../add-product-modal/add-product-modal-type.enum';

@Component({
  selector: 'app-request-to-join',
  templateUrl: './request-to-join.component.html',
  styleUrls: ['./request-to-join.component.scss'],
})
export class RequestToJoinComponent implements OnInit {
  @Output() onCancel = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<RequestToJoinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { orderType: string },
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.onCancel.emit();
    this.dialogRef.close();
  }
}
