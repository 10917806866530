import { Injectable } from '@angular/core';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, Subject, from } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PackingOrder } from '../model/packingOrder.model';
import { PackApiService } from './pack-api.service';
import { ConfirmService } from '../../utils/confirm/confirm.service';
import { ServiceDestroyed } from '../../core/decorators/take-until-destroy';
import { ScanOrderNumberModalComponent } from '../../utils/modals/scan-order-number-modal/scan-order-number-modal.component';
import { ScannerApiService } from '../../utils/services/scanner-api.service';
import { SpecialPackingOrdersInterface } from '../model/special-packing-orders.interface';
import { AddProductToBoxModel } from '../model/add-product-to-box.model';
import { List } from '../../core/models/list';
import { SpecialPackingContentsItemsModel } from '../model/special-packing-contents-items.model';
import { NumberOfPackagesModalComponent } from '../../utils/modals/number-of-packages-modal/number-of-packages-modal.component';
import { PackageApiService } from '../../utils/services/package/package-api.service';
import { ShippingMethodEnum } from '../../orders/models/shipping-method.enum';
import { OrderStatusEnum } from '../../core/enums/order-status.enum';
import { ActivePackingItems } from '../model/active-packing-items';
import { PackPayload } from '../model/pack-payload';
import { SpecialPackingTypeModalComponent } from '../../utils/modals/special-packing-type-modal/special-packing-type-modal.component';
import { PackingSelectPackageModalComponent } from '../../utils/modals/packing-select-package-modal/packing-select-package-modal.component';
import { OngoingOrderInterface } from '../model/ongoing-order.interface';

@Injectable({
  providedIn: 'root',
})
export class PackService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  packingOrders: PackingOrder[] = [];

  packingOngoingSessions: OngoingOrderInterface[] = [];

  specialPackingItemList: List<SpecialPackingOrdersInterface>;

  currentActiveBox: number = null;

  shippingMethod: string;

  constructor(
    private packApiService: PackApiService,
    private confirmService: ConfirmService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private scannerApiService: ScannerApiService,
    private router: Router,
    private toastr: ToastrService,
    private packageApiService: PackageApiService,
  ) {}

  getPackingOrder(packingOrderId: number, preloader = true): Observable<ActivePackingItems> {
    return this.packApiService.getPackingOrder(packingOrderId, preloader);
  }

  getBeingPackedOrders(): void {
    this.packApiService
      .getBeingPackedOrders()
      .pipe(tap(({ items }) => (this.packingOrders = items)))
      .subscribe();
  }

  getOngoingSessions(): void {
    this.packApiService
      .getOngoingSessions()
      .pipe(tap(({ items }) => (this.packingOngoingSessions = items)))
      .subscribe();
  }

  wrongExpiryDate(packingOrderId: number, productId: number): void {
    this.confirmService
      .show('Do you want report wrong expiry date?')
      .pipe(
        filter(decision => decision === true),
        switchMap(() => this.packApiService.wrongExpiryDateNotification(packingOrderId, productId)),
        tap(() => this.toastrService.success('Notification has been added')),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }

  endPacking(
    routeToSpecialPacking: boolean,
    orderId: number,
    shippingMethod: string,
    orderType: string,
    specialPacking = false,
  ): void {
    if (routeToSpecialPacking) {
      this.router.navigateByUrl(`pack-${orderType}/${orderId}/special-packing`);
      return;
    }

    // Run when shipping method is palets
    if (shippingMethod === ShippingMethodEnum.PALETS && !specialPacking) {
      this.setTypeOfPackageInOrder(orderId, shippingMethod)
        .pipe(
          switchMap(() =>
            this.checkConfirmOrderNumber(orderId, '/pack').pipe(
              switchMap(() => this.packApiService.changeOrderStatus(orderId, OrderStatusEnum.READY_TO_DISPATCH)),
            ),
          ),
          takeUntil(this.serviceDestroyed),
        )
        .subscribe();

      // Run when shipping method is special packing + palets
    } else if (shippingMethod === ShippingMethodEnum.PALETS && specialPacking) {
      this.checkConfirmOrderNumber(orderId, '/pack')
        .pipe(switchMap(() => this.packApiService.changeOrderStatus(orderId, OrderStatusEnum.READY_TO_DISPATCH)))
        .subscribe();

      // Run when shipping method is innoship
    } else if (shippingMethod.includes('innoship') && !specialPacking) {
      this.setTypeOfPackageInOrder(orderId, shippingMethod)
        .pipe(
          switchMap(() => this.checkConfirmOrderNumber(orderId, `/pack-${orderType}/print-label/${orderId}`)),
          takeUntil(this.serviceDestroyed),
        )
        .subscribe();

      // Run when shipping method is innoship + special packing
    } else if (shippingMethod.includes('innoship') && specialPacking) {
      this.checkConfirmOrderNumber(orderId, `/pack-${orderType}/print-label/${orderId}`).subscribe();
    }
  }

  setTypeOfPackageInOrder(orderId: number, shippingMethod: string): Observable<void> {
    const dialogRefSelectPackage = this.dialog.open(PackingSelectPackageModalComponent, {
      disableClose: true,
      width: 'auto',
      maxHeight: '90vh',
      data: shippingMethod,
    });

    return dialogRefSelectPackage.componentInstance.onApprove.pipe(
      switchMap(({ package: { id: packageId } }) => {
        const dialogRef = this.dialog.open(NumberOfPackagesModalComponent, {
          disableClose: true,
          width: 'auto',
          maxHeight: '90vh',
        });

        return dialogRef.componentInstance.onSave.pipe(
          switchMap(count => {
            const packageSizes = [];
            for (let i = 0; i < count; i++) {
              packageSizes.push(packageId);
            }
            return this.packageApiService.setNumberOfPackageToPack({ order: orderId, packageSizes });
          }),
        );
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  checkConfirmOrderNumber(orderId: number, redirect: string): Observable<any> {
    const orderNumberRef = this.dialog.open(ScanOrderNumberModalComponent, {
      width: '600px',
      maxHeight: '90vh',
    });

    return orderNumberRef.componentInstance.onOrderNumberScan.pipe(
      switchMap((orderIdScanned: string) =>
        this.scannerApiService.getOrder(orderIdScanned).pipe(
          switchMap(({ id }) => {
            if (orderId === id) {
              return this.packApiService.endPacking(orderId);
            } else {
              this.toastr.error('Order number does not match');
              return EMPTY;
            }
          }),
        ),
      ),
      tap(() => {
        orderNumberRef.close();
        this.toastrService.success('Packing has been finished!');
        this.router.navigateByUrl(redirect);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  getSpecialPackingItemList(orderId: number): Observable<List<SpecialPackingOrdersInterface>> {
    return this.packApiService.specialPackingItemList(orderId).pipe(
      tap(itemList => {
        this.specialPackingItemList = itemList;
        this.specialPackingItemList.items.map(item => {
          item.contents.items.map(el => (el.product.box = item.id));
          item.contents.items.map(el => (el.orderId = orderId));
          item.active = false;
          item.orderId = orderId;
        });
        this.changeActiveBox(this.currentActiveBox);
      }),
    );
  }

  addBoxToOrder(order: number, shippingMethod: string) {
    const dialogRef = this.dialog.open(PackingSelectPackageModalComponent, {
      disableClose: true,
      width: 'auto',
      maxHeight: '90vh',
      data: shippingMethod,
    });

    return dialogRef.componentInstance.onApprove.pipe(
      switchMap(({ package: { id } }) => this.packApiService.addBoxToOrder(order, id)),
      switchMap(() => this.getSpecialPackingItemList(order)),
      tap(() => this.toastr.success('Box has been added')),
    );
  }

  addProductToBox(orderId: number, payload: AddProductToBoxModel) {
    return this.packApiService.addProductToBox(payload).pipe(switchMap(() => this.getSpecialPackingItemList(orderId)));
  }

  removeProductFromBox(orderId: number, payload: AddProductToBoxModel) {
    return this.confirmService.show('Do you really want to take out this product?').pipe(
      filter(decision => decision),
      switchMap(() => this.packApiService.removeProductFromBox(payload)),
      tap(() => {
        this.toastr.success('Product has been taken out!');
      }),
      switchMap(() => this.getSpecialPackingItemList(orderId)),
    );
  }

  removeBox(packageOrder: number, orderId: number) {
    return this.confirmService.show('Do you really want to remove this box?').pipe(
      filter(decision => decision),
      switchMap(() => this.packApiService.removeBox(packageOrder)),
      tap(() => {
        this.toastr.success('Box has been removed!');
      }),
      switchMap(() => this.getSpecialPackingItemList(orderId)),
    );
  }

  get specialUnassignedProductList(): SpecialPackingContentsItemsModel[] {
    return this.specialPackingItemList?.items?.filter(item => item.name === 'unassigned')[0]?.contents?.items;
  }

  changeActiveBox(boxId: number) {
    this.currentActiveBox = boxId;
    this.specialPackingItemList.items.map(item => (item.active = false));
    this.specialPackingItemList.items.filter(item => item.id === boxId).map(item => (item.active = !item.active));
  }

  packOrder(payload: PackPayload): Observable<void> {
    return this.packApiService.packOrder(payload).pipe(
      tap(() => this.toastrService.success('Success!')),
      takeUntil(this.serviceDestroyed),
    );
  }

  resetPacking(packingOrderId: number): Observable<void> {
    return this.packApiService.resetPacking(packingOrderId).pipe(
      tap(() => {
        this.toastrService.success('Packing has been reset!');
        this.router.navigateByUrl('pack').then(r => r);
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  pausePacking(packingOrderId: number): Observable<void> {
    return this.packApiService.pausePacking(packingOrderId).pipe(
      switchMap(() => this.packApiService.resetPacking(packingOrderId)),
      tap(() => {
        this.router.navigateByUrl('pack');
        this.toastrService.success('Packing has been paused!');
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  cancelPacking(packingOrderId: number): Observable<void> {
    return this.packApiService.cancelPacking(packingOrderId).pipe(
      tap(() => {
        this.router.navigateByUrl('pack');
        this.toastrService.success('Packing has been canceled!');
      }),
      takeUntil(this.serviceDestroyed),
    );
  }

  packingTypeModal(orderId: number, shippingMethod: string): Observable<any> {
    let createdBoxId: number = null;
    const packingModalDialogRef = this.dialog.open(SpecialPackingTypeModalComponent, {
      disableClose: true,
      width: 'auto',
      maxHeight: '90vh',
      data: { quantity: 1, approve: 'Put', reject: 'Cancel' },
    });

    return packingModalDialogRef.componentInstance.onPackAsOneBox.pipe(
      switchMap(() => this.addBoxToOrder(orderId, shippingMethod)),
      switchMap(() => {
        createdBoxId = this.specialPackingItemList.items.filter(({ name }) => name === 'BOX 1')[0].id;
        const items = this.specialPackingItemList.items.filter(({ name }) => name !== 'undefined')[0].contents.items;
        return from(items);
      }),
      switchMap(({ product: { id: product, seriesId: series }, quantity }) => {
        const payload: AddProductToBoxModel = {
          packageOrder: createdBoxId,
          product,
          series,
          quantity,
        };
        return this.addProductToBox(orderId, payload);
      }),
      tap(() => this.toastr.success('Items has been packed!')),
      takeUntil(this.serviceDestroyed),
    );
  }
}
