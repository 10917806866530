import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PackingItem } from '../../../pack/model/packItem.model';
import { PackingActionsModalComponent } from '../../modals/packing-actions-modal/packing-actions-modal.component';
import { Replenishment } from '../../../replenishment/models/replenishment.model';
import { CollectingActionsModalComponent } from '../../modals/collecting-actions-modal/collecting-actions-modal.component';
import { CollectItem } from '../../../collect/models/collectItem.model';
import { ConfirmService } from '../../confirm/confirm.service';
import { ReplenishmentApiService } from '../../../replenishment/services/replenishment-api.service';
import { FiltersService } from '../../filters/services/filters.service';
import { TakeUntilDestroy } from '../../../core/decorators/take-until-destroy';
import { ReplenishmentRelatedEditModalComponent } from '../../../replenishment/components/modals/replenishment-related-edit-modal/replenishment-related-edit-modal.component';
import { DefaultMobileTableType } from './default-mobile-table-type';
import { CollectPreparedProduct } from '../../../collect/models/collect-prepared-product.model';
import { Order } from '../../../collect/models/order.model';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { ShowProductModalComponent } from '../../modals/show-product-modal/show-product-modal.component';
import { TransferRequestItem } from '../../../transferRequest/models/transferRequestItem.model';
import { TransferRequestService } from '../../../transferRequest/services/transferRequest.service';
import { PackService } from '../../../pack/services/pack.service';
import { SpecialPackingContentsItemsModel } from '../../../pack/model/special-packing-contents-items.model';
import { HandlingPalletsTask } from '../../../handling-pallets/components/models/handling-pallets-task.model';
import { HandlingPaletsTaskType } from '../../../handling-pallets/components/models/handling-palets-task-type.enum';
import { LocationProduct } from '../../../location/models/location-product.model';
import { LocalStorageKeysEnum } from '../../../core/enums/local-storage-keys.enum';

@Component({
  selector: 'app-packing-mobile-table',
  templateUrl: './default-mobile-table.component.html',
})
@TakeUntilDestroy
export class DefaultMobileTableComponent implements OnInit, OnDestroy {
  @Input() data: PackingItem &
    Replenishment &
    CollectItem &
    CollectPreparedProduct &
    TransferRequestItem &
    SpecialPackingContentsItemsModel &
    HandlingPalletsTask &
    LocationProduct;

  @Input() className: string;

  @Input() type: string;

  componentType = DefaultMobileTableType;

  collapse = true;

  componentDestroy: () => Observable<void>;

  activeOrders = this.localStorageService.getItem<Order[]>(LocalStorageKeysEnum.COLLECTING_ACTIVE_SESSIONS);

  boxNumbers = [];

  handlingPalletsType = HandlingPaletsTaskType;

  constructor(
    private dialog: MatDialog,
    private confirmService: ConfirmService,
    private replenishmentApiService: ReplenishmentApiService,
    private toastr: ToastrService,
    private filtersService: FiltersService,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService,
    private transferRequestService: TransferRequestService,
    private packService: PackService,
  ) {}

  ngOnInit() {
    if (this.type === this.componentType.collecting || this.type === this.componentType.collected)
      this.boxNumbers = [
        ...new Set(this.activeOrders.filter(({ boxNumber }) => boxNumber).map(({ boxNumber }) => boxNumber)),
      ];
  }

  ngOnDestroy() {}

  showActions(): void {
    switch (this.type) {
      case 'pack':
        this.dialog.open(PackingActionsModalComponent, {
          data: {
            title: this.data.product.name,
            id: this.data.product.id,
            packingOrderId: this.data.packingOrderId,
            image: this.data?.product?.image?.url,
          },
        });
        break;

      case 'collecting':
      case 'collected':
        this.dialog.open(CollectingActionsModalComponent, {
          data: this.data,
        });
        break;

      case 'replenishmentReservedByEmployee':
        this.dialog.open(ShowProductModalComponent, {
          width: '90vw',
          maxWidth: '500px',
          data: this.data,
        });
        break;

      default:
        return null;
    }
  }

  changeCollapse() {
    this.collapse = !this.collapse;
  }

  cancelReservation() {
    this.confirmService
      .show('Do you want cancel this reserve?')
      .pipe(
        filter(decision => decision === true),
        switchMap(() => this.replenishmentApiService.deleteReservedReplanishment(this.data.id)),
        tap(() => {
          this.toastr.success('Reserved has been canceled');
          this.filtersService.emitRefreshSubject();
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  reserveReplenishment() {
    const genericFilters = {
      'filters[0][field]': 'sourceLocation.id',
      'filters[0][cond]': 'eq',
      'filters[0][val]': this.data.sourceLocation.id,
    };

    this.replenishmentApiService
      .getRelatedReplenishment(this.filtersService.filters, genericFilters)
      .pipe(
        switchMap(response => {
          if (response.items.length > 1) {
            const relatedLocationsToShow = response.items.filter(({ id }) => id !== this.data.id);
            this.modalService.show(ReplenishmentRelatedEditModalComponent, {
              initialState: {
                replenishmentId: this.data.id,
                relatedLocations: response.items,
                relatedLocationsToShow,
              },
            });
            return of(null);
          }
          return this.confirmService.show('Do you want to reserve this item?').pipe(
            filter(decision => decision === true),
            switchMap(() => this.replenishmentApiService.postReserveReplenishment([this.data.id])),
            tap(() => {
              this.toastr.success('Product has been reserved');
            }),
            tap(() => this.filtersService.emitRefreshSubject()),
            takeUntil(this.componentDestroy()),
          );
        }),
        takeUntil(this.componentDestroy()),
      )
      .subscribe();
  }

  cancelTransferRequestReservation() {
    this.transferRequestService.cancelReservation(this.data.id).pipe(takeUntil(this.componentDestroy())).subscribe();
  }

  removeTransferRequest() {
    this.transferRequestService
      .removeTransferRequest(this.data.id)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe();
  }

  reserveTransferRequest() {
    this.transferRequestService
      .reserve(this.data.sourceLocation.id, this.data.product.seriesId, this.data.id)
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe();
  }

  removeProduct() {
    const {
      quantity,
      orderId,
      product: { box: packageOrder, id: product, seriesId: series },
    } = this.data;

    this.packService.removeProductFromBox(orderId, { quantity, packageOrder, product, series }).subscribe();
  }
}
