import { Component, Input, OnDestroy } from '@angular/core';
import { finalize, retry, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TakeUntilDestroy } from '../../core/decorators/take-until-destroy';
import { UploadModel } from './upload.model';
import { ProgressBarComponent } from '../modals/progress-bar/progress-bar.component';
import imageCompression from 'browser-image-compression';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
@TakeUntilDestroy
export class UploadComponent implements OnDestroy {
  componentDestroy: () => Observable<void>;

  @Input() httpUpload: (file: UploadModel) => Observable<unknown>;

  @Input() afterUpload: () => unknown;

  @Input() uploadButtonLabel = 'Upload file';

  @Input() finishButtonLabel = 'Finish';

  @Input() accept = 'image/png, image/jpeg, image/jpg';

  @Input() multiple = true;

  dialogRef: MatDialogRef<ProgressBarComponent>;

  files: UploadModel[] = [];

  constructor(private toastrService: ToastrService, private dialog: MatDialog) {}

  ngOnDestroy() {}

  uploadFiles(index = 1) {
    const currentIndex = index - 1;

    if (this.files.length <= 0) {
      this.toastrService.info('Please select the files first');
    }

    const imageFile = this.files[currentIndex];
    if (imageFile) {
      if (!this.dialogRef) {
        this.dialogRef = this.dialog.open(ProgressBarComponent, {
          panelClass: 'progress-bar-modal',
          data: { barText: 'Upload photos-tab...', total: this.files.length },
        });
      }

      imageCompression(imageFile.file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1280,
        useWebWorker: true,
      }).then(compressedFile => {
        imageFile.file = compressedFile;

        this.httpUpload(imageFile)
          .pipe(
            retry(3),
            finalize(() => {
              if (this.files.length > index) {
                this.dialogRef.componentInstance.uploadStatus += 1;
                this.uploadFiles(index + 1);
              } else {
                this.dialogRef.componentInstance.uploadStatus += 1;
                this.toastrService.success('Files has been added!');
                this.clearFiles();
              this.dialogRef = null;

                if (typeof this.afterUpload === 'function') {
                  this.afterUpload();
                }
              }
            }),
            takeUntil(this.componentDestroy()),
          )
          .subscribe();
      });
    }
  }

  onFileChange($event) {
    const files = $event.target.files as FileList;

    if (files.length) {
      if ($event.target.files.length > 1) {
        // eslint-disable-next-line no-restricted-syntax
        for (const file of $event.target.files) {
          this.readFile(file).subscribe(data => this.files.push(data));
        }
      } else {
        this.readFile(files[0]).subscribe(data => this.files.push(data));
      }
    }
  }

  readFile(file: File): Observable<UploadModel> {
    return new Observable(obs$ => {
      const reader = new FileReader();

      reader.onerror = err => obs$.error(err);
      reader.onabort = err => obs$.error(err);
      reader.onload = () =>
        obs$.next({
          name: `file-${Date.now()}-${file.name}`,
          file,
          fileName: file.name,
          url: reader.result,
        } as UploadModel);
      reader.onloadend = () => obs$.complete();

      reader.readAsDataURL(file);
    });
  }

  removeFile(index): void {
    this.files = this.files.filter((_, key) => key !== index);
  }

  clearFiles(): void {
    this.files = [];
  }
}
