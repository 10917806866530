import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import { apiRoutes } from '../../../environments/api-routes';
import { ServiceDestroyed, TakeUntilDestroy } from '../decorators/take-until-destroy';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthorizationService } from '../../security/services/authorization.service';
import { LocalStorageService } from './local-storage.service';
import { v4 as uuidv4 } from 'uuid';
import { CheckLogTimeType } from '../enums/check-log-time-type.enum';
import moment from 'moment';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
@TakeUntilDestroy
export class CheckLogTimeService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  apiRoutes = apiRoutes;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnDestroy() {}

  log(action: 'start' | 'stop', module: string = null, type: CheckLogTimeType = CheckLogTimeType.MAIN) {
    let device = this.localStorageService.getItem(LocalStorageKeysEnum.DEVICE_ID);
    if (!device) {
      device = uuidv4();
      this.localStorageService.setItem(LocalStorageKeysEnum.DEVICE_ID, device);
    }

    if (module && module.includes('additional')) type = CheckLogTimeType.ADDITIONAL;

    const payload = {
      module,
      action,
      type,
      device,
      created_at: moment(new Date()).format(`YYYY-MM-DD HH:mm:ss`),
    };

    this.http
      .post(this.apiService.getMikuUrl(this.apiRoutes.reports.temporaryActivityReport), payload, {
        params: { preloader: false, errorNotification: false },
      })
      .pipe(takeUntil(this.serviceDestroyed))
      .subscribe();
  }
}
