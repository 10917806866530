import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs';
import { List } from '../models/list';
import { WarehouseListInterface } from '../models/warehouse-list.interface';
import { ApiService } from '../api.service';
import { apiRoutes } from '../../../environments/api-routes';
import { HttpClient } from '@angular/common/http';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  private apiRoutes = apiRoutes;

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private http: HttpClient,
  ) {}

  get warehouseId(): number {
    return this.localStorageService.getItem(LocalStorageKeysEnum.WAREHOUSE);
  }

  setWarehouseId(id: number) {
    if (id) this.localStorageService.setItem(LocalStorageKeysEnum.WAREHOUSE, id);
  }

  getWarehouseList(): Observable<List<WarehouseListInterface>> {
    return this.http.get<List<WarehouseListInterface>>(this.apiService.getMikuUrl(this.apiRoutes.open.warehouseList));
  }
}
