import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { DateHelpersService } from './date-helpers.service';
import { AuthorizationService } from '../../security/services/authorization.service';
import { Subject, fromEvent, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ServiceDestroyed } from '../decorators/take-until-destroy';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class EveryDayLogoutService implements ServiceDestroyed {
  serviceDestroyed = new Subject<void>();

  currentDate: string = null;

  lastLoginDate: string = null;

  constructor(
    private localStorageService: LocalStorageService,
    private dateHelpersService: DateHelpersService,
    private authorizationService: AuthorizationService,
  ) {}

  init(): void {
    this.tracker();
  }

  correctLoginCheck(): void {
    this.currentDate = this.dateHelpersService.formatDate(new Date())?.toString();
    this.lastLoginDate = this.localStorageService.getItem(LocalStorageKeysEnum.LAST_LOGIN_DATE)?.toString();

    const numberOfDaysBetweenDates = this.dateHelpersService.numberOfDaysBetweenDates(
      this.currentDate,
      this.lastLoginDate,
    );

    if (!this.lastLoginDate || (this.lastLoginDate && (numberOfDaysBetweenDates > 0 || numberOfDaysBetweenDates < 0))) {
      this.authorizationService.logout();
    }
  }

  setLastLoginDate(): void {
    this.localStorageService?.setItem(
      LocalStorageKeysEnum.LAST_LOGIN_DATE,
      this.dateHelpersService.formatDate(new Date()),
    );
  }

  tracker(): void {
    const click$ = fromEvent(document, 'click');
    const touchEnd$ = fromEvent(document, 'touchend');

    merge(click$, touchEnd$)
      .pipe(
        tap(() => this.correctLoginCheck()),
        takeUntil(this.serviceDestroyed),
      )
      .subscribe();
  }
}
