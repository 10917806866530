import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/api.service';
import { apiRoutes } from 'src/environments/api-routes';
import { Router } from '@angular/router';
import { Authorization } from '../components/login/models/authorization.interface';
import { Credentials } from '../components/login/models/credentials.interface';
import { MenuService } from '../../miku-menu/services/menu.service';
import { IdleTime } from '../../core/services/idle-time.service';
import { UserModel } from '../../core/models/user.model';
import * as Sentry from '@sentry/angular-ivy';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { tap } from 'rxjs/operators';
import { LocalStorageKeysEnum } from '../../core/enums/local-storage-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private privilages = new BehaviorSubject(JSON.parse(localStorage.getItem(LocalStorageKeysEnum.PRIVILEGES)) || []);

  private apiRoutes = apiRoutes;

  idleTime: IdleTime;

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
    private menuService: MenuService,
    private localStorageService: LocalStorageService,
  ) {}

  isUserLoggedIn(): boolean {
    return !!this.getToken();
  }

  logout(): void {
    localStorage.removeItem(LocalStorageKeysEnum.JWT);
    localStorage.removeItem(LocalStorageKeysEnum.SESSION_JWT);
    localStorage.removeItem(LocalStorageKeysEnum.PRIVILEGES);
    localStorage.removeItem(LocalStorageKeysEnum.NAVBAR_OPEN);
    localStorage.removeItem(LocalStorageKeysEnum.ERROR_USER_FEEDBACK);
    localStorage.removeItem(LocalStorageKeysEnum.PUSHER_TRANSPORT_TLS);
    localStorage.removeItem(LocalStorageKeysEnum.EXPIRED_TIME);
    localStorage.removeItem(LocalStorageKeysEnum.LAST_LOGIN_DATE);
    localStorage.removeItem(LocalStorageKeysEnum.AUTO_LOGOUT_TIME);
    localStorage.removeItem(LocalStorageKeysEnum.PAGE_REFRESH_COUNT);
    localStorage.removeItem(LocalStorageKeysEnum.SETTINGS);
    Sentry.setUser({ ip_address: '{{auto}}' });
    this.menuService.closeMenu();
    this.router.navigateByUrl('/login');
  }

  idleUserLogoutTimer(): void {
    const autoLogoutTime = parseInt(this.localStorageService.getItem(LocalStorageKeysEnum.AUTO_LOGOUT_TIME), 10);
    if (autoLogoutTime && autoLogoutTime > 60) {
      this.idleTime = new IdleTime({
        timeout: autoLogoutTime,
        onTimeout: () => {
          this.logout();
          this.logOut().subscribe();
        },
      });
    }
  }

  getToken(): string {
    return localStorage.getItem(LocalStorageKeysEnum.JWT);
  }

  getSessionToken(): string {
    return localStorage.getItem(LocalStorageKeysEnum.SESSION_JWT);
  }

  setToken(token: string): void {
    localStorage.setItem(LocalStorageKeysEnum.JWT, token);
  }

  setSessionToken(token: string): void {
    localStorage.setItem(LocalStorageKeysEnum.SESSION_JWT, token);
  }

  getLoggedUserId(): number {
    const payload = this.getTokenPayload();
    return payload.id;
  }

  getUser() {
    return this.localStorageService.getItem(LocalStorageKeysEnum.LOGGED_USER);
  }

  setPrivileges(privileges: string[]): void {
    localStorage.setItem(LocalStorageKeysEnum.PRIVILEGES, JSON.stringify(privileges));
    this.privilages.next(privileges);
  }

  getPrivileges$(): Observable<string[]> {
    return this.privilages.asObservable();
  }

  /**
   * @deprecated
   */
  checkPrivilegePathForNotLoggedUser(url: string): boolean {
    if (url.indexOf('/login') || url.indexOf('/password/recovery') || url.indexOf('/password/reset')) {
      return true;
    }
    return false;
  }

  hasAccess(acl: string[]): boolean {
    return this.privilages.value.sort().join().includes(acl.sort().join());
  }

  getTokenPayload(): any {
    if (this.getToken()) return JSON.parse(atob(this.getToken().split('.')[1]));
  }

  logIn(credentials: Credentials): Observable<Authorization> {
    return this.http.post<Authorization>(this.apiService.getMikuUrl(this.apiRoutes.dashboard.logIn), credentials);
  }

  logOut(): Observable<void> {
    return this.http.delete<void>(this.apiService.getMikuUrl(this.apiRoutes.dashboard.logOut), {
      params: { errorNotification: false },
    });
  }

  getUserProfile(): Observable<UserModel> {
    return this.http.get<UserModel>(this.apiService.getMikuUrl(this.apiRoutes.dashboard.profile));
  }

  twoFactorRegister(code: string, token: string) {
    const options = {
      headers: new HttpHeaders().set('X-AUTH-TOKEN', token),
    };

    return this.http.post<Authorization>(
      this.apiService.getMikuUrl(this.apiRoutes.twoFactor.register),
      { code },
      options,
    );
  }

  twoFactorAuthenticate(code: string, token: string) {
    const options = {
      headers: new HttpHeaders().set('X-AUTH-TOKEN', token),
    };
    return this.http.post<Authorization>(
      this.apiService.getMikuUrl(this.apiRoutes.twoFactor.authenticate),
      { code },
      options,
    );
  }

  refreshToken(): Observable<Authorization> {
    return this.http
      .post<Authorization>(this.apiService.getMikuUrl(this.apiRoutes.dashboard.refreshToken), null, {
        params: { isSessionToken: true },
      })
      .pipe(
        tap(({ session, token }) => {
          this.setSessionToken(session);
          this.setToken(token);
        }),
      );
  }
}
